import JaneStreet from '../../images/sponsors/JaneStreet.png';
import AoPS from '../../images/sponsors/AoPS.png';
import Wolfram from '../../images/sponsors/Wolfram.png';
import Maple from '../../images/sponsors/Maple.png';

const sponsors = [
  {
    name: 'Jane Street',
    image: JaneStreet
  },
  {
    name: 'AoPS',
    image: AoPS
  },
  {
    name: 'Wolfram',
    image: Wolfram
  },
  {
    name: 'Maple',
    image: Maple
  }
];

export default sponsors;
