import { Link } from 'react-router-dom';
import benefits from '../../data/course/benefits';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BenefitsGrid() {
  return (
    <div>
      <div className="rounded-lg shadow-lg border border-gray-200 bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
        {benefits.map((benefit, benefitIdx) => (
          <div
            key={benefit.title}
            className={classNames(
              benefitIdx === 0
                ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                : '',
              benefitIdx === 2 ? 'sm:rounded-tr-lg' : '',
              benefitIdx === benefits.length - 3 ? 'sm:rounded-bl-lg' : '',
              benefitIdx === benefits.length - 1
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  benefit.iconBackground,
                  benefit.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <benefit.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-2">
              <h3 className="text-lg font-medium">
                {benefit.href.startsWith('https') ? (
                  <a
                    href={benefit.href}
                    target="_blank"
                    rel="noreferrer"
                    className="focus:outline-none"
                  >
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {benefit.title}
                  </a>
                ) : (
                  <Link to={benefit.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {benefit.title}
                  </Link>
                )}
              </h3>
              <p className="mt-2 text-sm text-gray-500">{benefit.desc}</p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
