import {
  LightBulbIcon,
  LockOpenIcon,
  SearchCircleIcon
} from '@heroicons/react/outline';

const goals = [
  {
    name: 'Inspire',
    description:
      'We instill a lifelong love of learning in aspiring scientists, equipping them with relevant, novel material to make change.',
    icon: LightBulbIcon
  },
  {
    name: 'Unlock',
    description:
      'Connect with a global community of avid learners of all backgrounds through free education by high-performing, well-trained instructors.',
    icon: LockOpenIcon
  },
  {
    name: 'Discover',
    description:
      'No more memorization: Develop a deeper understanding through detailed examples and explanations.',
    icon: SearchCircleIcon
  }
];

export default goals;
