export default function WhyEveraise() {
  return (
    <div>
      <p className="text-xl text-gray-500">
        Our goal at Everaise is to instill a lifelong love of learning in our
        students. We aren’t just a contest preparation bootcamp — although our
        program is excellent training for academic competitions, our real
        objective is to expose kids to cool and interesting material they might
        never have seen before. To that end, we offer students comprehensive,
        in-depth opportunities to learn new subjects — and come to love them,
        too.
      </p>
      <p className="mt-4 text-xl text-gray-500">
        In our courses, we emphasize building deeper understanding over
        memorizing techniques and formulas. We provide plenty of detailed
        examples in each handout, with each solution properly motivated and
        explained; our office hour sessions are run by some of the most talented
        high school and college students in their respective subject areas.
      </p>
      <p className="mt-4 text-xl text-gray-500">
        We believe that knowledge should be equally accessible to students of
        all backgrounds, which is why our courses will always be free for
        everyone.
      </p>
    </div>
  );
}
