import PrestonFu from '../images/team/PrestonFu.png';
import MatthewChen from '../images/team/MatthewChen.png';
import ChooiJeQin from '../images/team/ChooiJeQin.png';
import AshmitDutta from '../images/team/AshmitDutta.png';
import KrishJayarapu from '../images/team/KrishJayarapu.png';
import KiranSun from '../images/team/KiranSun.png';
import AnanthKashyap from '../images/team/AnanthKashyap.png';
import AbhayBestrapalli from '../images/team/AbhayBestrapalli.png';
import FarazAhmedSiddiqui from '../images/team/FarazAhmedSiddiqui.png';
import JeffreyWei from '../images/team/JeffreyWei.png';
import AnujSakarda from '../images/team/AnujSakarda.png';
import OliviaLi from '../images/team/OliviaLi.png';
import GaryHu from '../images/team/GaryHu.png';
import JustinHua from '../images/team/JustinHua.png';
import IvyZheng from '../images/team/IvyZheng.png';
import DerekChen from '../images/team/DerekChen.png';
import PeterPu from '../images/team/PeterPu.png';
import AnuragChittawar from '../images/team/AnuragChittawar.png';
import AniruddhaSharma from '../images/team/AniruddhaSharma.png';
import AtharvaMahajan from '../images/team/AtharvaMahajan.png';
import YamanAcharya from '../images/team/YamanAcharya.png';
import HaoliYin from '../images/team/HaoliYin.png';
import VictorLi from '../images/team/VictorLi.JPG';
import NathanMa from '../images/team/NathanMa.png';
import AlbertYe from '../images/team/AlbertYe.png';
import JeffreyHu from '../images/team/JeffreyHu.jpg';
import LohithTummala from '../images/team/LohithTummala.png';
import EvanKim from '../images/team/EvanKim.png';
import MuhammedYaseen from '../images/team/MuhammedYaseen.png';
import YushuZhang from '../images/team/YushuZhang.jpg';
import AidenPan from '../images/team/AidenPan.png';
import SunainaPati from '../images/team/SunainaPati.png';
import SamarthBarve from '../images/team/SamarthBarve.png';

import BrianLee from '../images/team/BrianLee.png';
import WilliamShi from '../images/team/WilliamShi.png';
import SaadiqShaikh from '../images/team/SaadiqShaikh.png';
import GregPylypovych from '../images/team/GregoryPylypovych.png';
import JustinShan from '../images/team/JustinShan.png';
import AndrewWu from '../images/team/AndrewWu.png';
import JenniferWang from '../images/team/JenniferWang.png';
import DennisChen from '../images/team/DennisChen.png';
import FionaAbneyMcPeek from '../images/team/FionaAbney-McPeek.jpeg';
import AndersOlsen from '../images/team/AndersOlsen.png';
import AlexIzmailov from '../images/team/AlexIzmailov.jpeg';
import SarahGleghorn from '../images/team/SarahGleghorn.jpg';
import ValentioIverson from '../images/team/ValentioIverson.png';
import SivaMuthupalaniappan from '../images/team/SivaMuthupalaniappan.png';
import NishantBhakar from '../images/team/NishantBhakar.jpg';
import LukeChoi from '../images/team/LukeChoi.JPG';
import JoshuaLee from '../images/team/JoshuaLee.jpg';
import SanjayNagaraj from '../images/team/SanjayNagaraj.png';
import NiyanthRao from '../images/team/NiyanthRao.png';
import CharlesYu from '../images/team/CharlesYu.png';
import DylanLiu from '../images/team/DylanLiu.png';
import AndrewYuan from '../images/team/AndrewYuan.png';
import JasonCheah from '../images/team/JasonCheah.jpg';
import SusiFeng from '../images/team/SusiFeng.jpg';
import AlbertZhu from '../images/team/AlbertZhu.jpeg';
import PoonamSahoo from '../images/team/PoonamSahoo.jpg';
import SanjitBasker from '../images/team/SanjitBasker.jpg';
import JinhaKim from '../images/team/JinhaKim.png';
import JessicaHe from '../images/team/JessicaHe.png';
import VismaySharan from '../images/team/VismaySharan.png';
import PeterLuo from '../images/team/PeterLuo.png';
import LaurenTan from '../images/team/LaurenTan.png';
import JiyoungLee from '../images/team/JiyoungLee.png';

const current = [
  {
    name: 'Matthew Chen',
    roles: ['Head of Math', 'Executive Team Member'],
    imageUrl: MatthewChen,
    achievements: [
      '2x USA(J)MO Qualifier',
      'PUMaC A Algebra 8th Place, Individual Finals 20th Place',
      'USEMO Distinction',
      'USAPHO Qualifier'
    ],
    education: `Wayzata High School, USA '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Ashmit Dutta',
    roles: ['Head of Physics', 'Executive Team Member'],
    imageUrl: AshmitDutta,
    achievements: [
      'Thomas Jefferson Physics Olympiad (TJPhO) Second Place',
      'Physics Unlimited Explorer Competition (PUEC) Special Award',
      'Online Physics Olympiad (OPhO) Problem Writer'
    ],
    education: `Langley High School, USA '23`,
    linkedInUrl: 'https://www.linkedin.com/in/ashmit-dutta-2447881aa/',
    gitHubUrl: ''
  },
  {
    name: 'Ananth Kashyap',
    roles: ['Director of Technology', 'Executive Team Member'],
    imageUrl: AnanthKashyap,
    achievements: [
      'Robotics Researcher at Carnegie Mellon University',
      '3x AIME Qualifier',
      '2022 AP Computer Science A Exam Perfect Scorer'
    ],
    education: `Fox Chapel Area High School, USA '23`,
    linkedInUrl: 'https://www.linkedin.com/in/ananth-kashyap-88b996209/',
    gitHubUrl: 'https://github.com/AAkashyap1'
  },
  {
    name: 'Krish Jayarapu',
    roles: ['Head of Biology', 'Executive Team Member'],
    imageUrl: KrishJayarapu,
    achievements: [
      'USABO Honorable Mention, Semifinalist',
      'Science Olympiad National Medalist',
      'Researcher at IU'
    ],
    education: `Chemical+Biomolecular Engineering @ Johns Hopkins University '26`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Kiran Sun',
    roles: ['Associate Head of Math', 'Executive Team Member'],
    imageUrl: KiranSun,
    achievements: [
      'Canadian Junior Mathematical Olympiad (CJMO) Qualifier',
      '2x Canadian Mathematical Olympiad Qualifying Repêchage (CMOQR) Qualifier',
      'AIME Qualifier'
    ],
    education: `St. George's School, Canada '24`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Abhay Bestrapalli',
    roles: ['Associate Head of Astronomy'],
    imageUrl: AbhayBestrapalli,
    achievements: [
      'Indian International Mathematical Olympiad Training Camp (IMOTC)',
      'IOAA 2021 Silver Medalist',
      'Indian Astronomy Selection Camp (IOAA OCSC)'
    ],
    education: `MIT '26`,
    linkedInUrl: 'https://www.linkedin.com/in/abhay-bestrapalli-739280222/',
    gitHubUrl: ''
  },
  {
    name: 'Faraz Ahmed Siddiqui',
    roles: ['Associate Head of Astronomy'],
    imageUrl: FarazAhmedSiddiqui,
    achievements: [
      'International Linguistics Olympiad 2022',
      '2x IOAA TST Qualifier',
      'NAO Qualifier'
    ],
    education: `Holy Family High School, India '24`,
    linkedInUrl: 'https://in.linkedin.com/in/faraz-ahmed-s-73a3ba216',
    gitHubUrl: ''
  },
  {
    name: 'Jeffrey Wei',
    roles: ['Associate Head of Astronomy'],
    imageUrl: JeffreyWei,
    achievements: [
      'NAC Qualifier',
      'AIME Qualifier',
      'Beaver Works Summer Institute Alumnus'
    ],
    education: `Acton Boxborough Regional High School, USA '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Evan Kim',
    roles: ['Physics', 'Curriculum Developer', 'Instructor'],
    imageUrl: EvanKim,
    achievements: [
      'USAPhO Semifinalist',
      'AMC 10 Distinguished Honor Roll',
      'AIME Qualifier',
      'National Science Bowl Qualifier'
    ],
    education: `Tesla STEM High School, USA '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Aniruddha Sharma',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: AniruddhaSharma,
    achievements: [
      'Physics Unlimited Explorer Competition (PUEC) Third Place',
      'Stanford Code in Place Alumnus',
      'Gaussian Curvature Mathematics Committee Member'
    ],
    education: `Rajeev Gandhi Higher Secondary School '20`,
    linkedInUrl: 'https://www.linkedin.com/in/asharma1729/',
    gitHubUrl: ''
  },
  {
    name: 'Ivy Zheng',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: IvyZheng,
    achievements: ['2x USAJMO Qualifier', '3x AMC 10 Distinguished Honor Roll'],
    education: `Lexington High School, USA '24`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Gary Hu',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: GaryHu,
    achievements: [
      'CMIMC 3rd Geometry & 4th Overall',
      'Canada/USA Mathcamp 2022'
    ],
    education: `Montclair Kimberley Academy, USA '24`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Muhammed Yaseen',
    roles: ['Biology', 'Curriculum Developer'],
    imageUrl: MuhammedYaseen,
    achievements: [
      'UAE IBO Team Selection Bronze',
      'BBO Gold',
      'Gulf Medical University HS Biomedial Program Admittee'
    ],
    education: `GEMS Our Own Indian School Dubai, UAE '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Yushu Zhang',
    roles: ['Astronomy', 'Curriculum Developer', 'Instructor'],
    imageUrl: YushuZhang,
    achievements: ['NAC Qualifier', '2x AIME Qualifier'],
    education: `Thomas Jefferson High School for Science and Technology, USA '24`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Aiden Pan',
    roles: ['Outreach'],
    imageUrl: AidenPan,
    achievements: ['USESO Training Camp', 'ISEF Finalist'],
    education: `Great Neck South High School, USA '24`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Sunaina Pati',
    roles: ['Math 1', 'Curriculum Developer'],
    imageUrl: SunainaPati,
    achievements: [
      'EGMO 2023 Silver Medalist',
      '3x Indian International Mathematical Olympiad Training Camp (IMOTC)',
      'PROMYS 2023 India Mehta Fellow',
      'Iranian Geometry Olympiad HM'
    ],
    education: `Sarala Birla Gyan Jyoti '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Samarth Barve',
    roles: ['Math 1', 'Curriculum Developer'],
    imageUrl: SamarthBarve,
    achievements: [
      'ISI Limit, 9th Place',
      'NMTC All India, 7th Place',
      'Physics Brawl, Open Category, 4th Place',
      'AIME qualifier'
    ],
    education: `Indian Institute of Technology, Kharagpur, '27`,
    linkedInUrl: 'https://www.linkedin.com/in/samarth-barve?trk=contact-info',
    gitHubUrl: ''
  }
];

const archive = [
  {
    name: 'Preston Fu',
    roles: ['Co-founder', 'Program Coordinator'],
    imageUrl: PrestonFu,
    achievements: [
      '2x USA(J)MO Qualifier',
      '2x USAPhO Honorable Mention',
      'Canadian Open Mathematics Challenge International Honor Roll'
    ],
    education: `EECS @ UC Berkeley '26`,
    linkedInUrl: 'https://www.linkedin.com/in/preston-fu/',
    gitHubUrl: ''
  },
  {
    name: 'Brian Lee',
    roles: ['Co-founder', 'Co-head of Physics', 'Finance'],
    imageUrl: BrianLee,
    achievements: [],
    education: `Math+CS @ MIT '25`,
    linkedInUrl: 'https://www.linkedin.com/in/brian-lee-70a3111ab/',
    gitHubUrl: ''
  },
  {
    name: 'William Shi',
    roles: ['Co-founder', 'Co-head of Physics'],
    imageUrl: WilliamShi,
    achievements: [],
    education: `Math+CS @ Harvard '25`,
    linkedInUrl: 'https://www.linkedin.com/in/william-shi-80/',
    gitHubUrl: ''
  },
  {
    name: 'Saadiq Shaikh',
    roles: ['Co-founder', 'Head of Math 1'],
    imageUrl: SaadiqShaikh,
    achievements: [],
    education: `Math+CS @ CMU '25`,
    linkedInUrl: 'https://www.linkedin.com/in/saadiq-shaikh-5a3785161/',
    gitHubUrl: ''
  },
  {
    name: 'Greg Pylypovych',
    roles: ['Co-founder', 'Head of Astronomy'],
    imageUrl: GregPylypovych,
    achievements: [],
    education: `Math+CS @ MIT '25`,
    linkedInUrl: 'https://www.linkedin.com/in/gregory-pylypovych-911542221/',
    gitHubUrl: ''
  },
  {
    name: 'Justin Shan',
    roles: ['Founding Member', 'Head of Biology'],
    imageUrl: JustinShan,
    achievements: [],
    education: `Neuroscience+CS @ MIT '25`,
    linkedInUrl: 'https://www.linkedin.com/in/justin-shan-1ab448194/',
    gitHubUrl: ''
  },
  {
    name: 'Andrew Wu',
    roles: ['Founding Member', 'Head of Math 2', 'Outreach Lead'],
    imageUrl: AndrewWu,
    achievements: [],
    education: `Math @ Yale '23`,
    linkedInUrl: 'https://www.linkedin.com/in/andrewgongwu/',
    gitHubUrl: ''
  },
  {
    name: 'Jennifer Wang',
    roles: ['Associate Head of Math 2'],
    imageUrl: JenniferWang,
    achievements: [],
    education: `MIT '25`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Chooi Je Qin',
    roles: ['Head of Astronomy'],
    imageUrl: ChooiJeQin,
    achievements: [
      'IESO 2018',
      'IAO 2019',
      'IOI 2021',
      'IPhO 2021',
      'IOAA 2021'
    ],
    education: `Astrophysics @ Harvard '26`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Dennis Chen',
    roles: ['Associate Head of Math 1'],
    imageUrl: DennisChen,
    achievements: [],
    education: `Amador Valley High School, USA '23`,
    linkedInUrl: 'https://www.linkedin.com/in/dennisrchen/',
    gitHubUrl: ''
  },
  {
    name: 'Fiona Abney-McPeek',
    roles: ['Physics', 'Curriculum Developer', 'Instructor'],
    imageUrl: FionaAbneyMcPeek,
    achievements: [],
    education: `University of Chicago Laboratory Schools, USA '21`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Anders Olsen',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: AndersOlsen,
    achievements: [],
    education: `Math+CS @ MIT '22`,
    linkedInUrl: 'https://www.linkedin.com/in/anders-olsen-1957b7224/',
    gitHubUrl: ''
  },
  {
    name: 'Lohith Tummala',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: LohithTummala,
    achievements: [
      'USA(J)MO Qualifier',
      '2x AMC Distinguished Honor Roll',
      '2x MathCounts National Qualifier'
    ],
    education: `Electrical and Computer Engineering @ CMU '25`,
    linkedInUrl: 'https://www.linkedin.com/in/ltummala/',
    gitHubUrl: ''
  },
  {
    name: 'Jeffrey Hu',
    roles: ['Math 1', 'Physics', 'Curriculum Developer', 'Instructor'],
    imageUrl: JeffreyHu,
    achievements: [
      '3x AIME qualifier',
      'USAPhO qualifier',
      'Santa Clara University Math Contest Honorable Mention'
    ],
    education: `CS @ University of Minnesota '26`,
    linkedInUrl: 'https://www.linkedin.com/in/jeffrey-hu-8473b918b/',
    gitHubUrl: ''
  },
  {
    name: 'Alex Izmailov',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: AlexIzmailov,
    achievements: [],
    education: `Math+CS @ Brown '24`,
    linkedInUrl: 'https://www.linkedin.com/in/alizma0/',
    gitHubUrl: ''
  },
  {
    name: 'Sarah Gleghorn',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: SarahGleghorn,
    achievements: [],
    education: `Math @ Cambridge '24`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Valentio Iverson',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: ValentioIverson,
    achievements: [],
    education: `CS @ University of Waterloo '26`,
    linkedInUrl: 'https://www.linkedin.com/in/valentio-iverson-0252771b1/',
    gitHubUrl: ''
  },
  {
    name: 'Siva Muthupalaniappan',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: SivaMuthupalaniappan,
    achievements: [],
    education: `Math @ Harvard '23`,
    linkedInUrl: 'https://www.linkedin.com/in/smuthu1618/',
    gitHubUrl: ''
  },
  {
    name: 'Nishant Bhakar',
    roles: ['Physics', 'Curriculum Developer', 'Instructor'],
    imageUrl: NishantBhakar,
    achievements: [],
    education: `CS+Physics @ UC Berkeley '24`,
    linkedInUrl: 'https://www.linkedin.com/in/nishant-bhakar/',
    gitHubUrl: ''
  },
  {
    name: 'Luke Choi',
    roles: ['Math 1', 'Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: LukeChoi,
    achievements: [],
    education: `Brown '25`,
    linkedInUrl: 'https://www.linkedin.com/in/luke-choi-6876281b5/',
    gitHubUrl: ''
  },
  {
    name: 'Joshua Lee',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: JoshuaLee,
    achievements: [],
    education: `Math+CS @ MIT '25`,
    linkedInUrl: 'https://www.linkedin.com/in/joshua-lee-53400b1a0/',
    gitHubUrl: ''
  },
  {
    name: 'Sanjay Nagaraj',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: SanjayNagaraj,
    achievements: [],
    education: `CS @ Stanford '24`,
    linkedInUrl: 'https://www.linkedin.com/in/sanjay-nagaraj/',
    gitHubUrl: ''
  },
  {
    name: 'Albert Ye',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: AlbertYe,
    achievements: [
      '3x AIME Qualifier',
      'AMC 10 Distinguished Honor Roll',
      'USAMTS Leaderboard'
    ],
    education: `Math+CS @ UC Berkeley '26`,
    linkedInUrl: 'https://www.linkedin.com/in/albert-ye-587aa81a2/',
    gitHubUrl: ''
  },
  {
    name: 'Niyanth Rao',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: NiyanthRao,
    achievements: [],
    education: `Math+CS @ CMU '25`,
    linkedInUrl: 'https://www.linkedin.com/in/niyanthr/',
    gitHubUrl: ''
  },
  {
    name: 'Charles Yu',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: CharlesYu,
    achievements: [],
    education: `Princeton '26`,
    linkedInUrl: 'https://www.linkedin.com/in/charles-yu-35921819a/',
    gitHubUrl: ''
  },
  {
    name: 'Dylan Liu',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: DylanLiu,
    achievements: [],
    education: `Math+CS @ MIT '23`,
    linkedInUrl: 'https://www.linkedin.com/in/dylan-liu-9b4352193/',
    gitHubUrl: ''
  },
  {
    name: 'Andrew Yuan',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: AndrewYuan,
    achievements: [],
    education: `Math+CS @ Yale '23`,
    linkedInUrl: 'https://www.linkedin.com/in/andrew-yuan-01702a156/',
    gitHubUrl: ''
  },
  {
    name: 'Jason Cheah',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: JasonCheah,
    achievements: [],
    education: `College of William & Mary '25`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Peter Pu',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: PeterPu,
    achievements: ['USABO Finalist (Top 20)', 'AMC 10 DHR', 'AMC 12 DHR'],
    education: `Bioengineering @ MIT '26`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Nathan Ma',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: NathanMa,
    achievements: [
      '2x AMC 10 Distinguished Honor Roll',
      '2x AIME Qualifier',
      'MathCounts Nationals Qualifier'
    ],
    education: `Stevenson High School, USA '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Atharva Mahajan',
    roles: ['Physics', 'Curriculum Developer', 'Instructor'],
    imageUrl: AtharvaMahajan,
    achievements: [
      'Indian Physics Selection Camp (OCSC)',
      'KVPY Top 50',
      'RMO Qualifier'
    ],
    education: `Physics @ Oxford University '26`,
    linkedInUrl: 'https://www.linkedin.com/in/atharva-mahajan-5957b4212/',
    gitHubUrl: ''
  },
  {
    name: 'Victor Li',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: VictorLi,
    achievements: ['2020 USABO Top 20 Finalist', '2018 USESO Finalist'],
    education: `MIT '25`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Justin Hua',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: JustinHua,
    achievements: ['Ross Mathematics Program'],
    education: `Hugh McRoberts Secondary School, Canada '21`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Haoli Yin',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: HaoliYin,
    achievements: [
      'USABO Semifinalist',
      '2x Science Olympiad National Biology Event Medalist',
      'Global Hackathon Top 5'
    ],
    education: `CS+Pre-Med @ Vanderbilt University '25`,
    linkedInUrl: 'https://www.linkedin.com/in/haoliyin/',
    gitHubUrl: ''
  },
  {
    name: 'Yaman Acharya',
    roles: ['Astronomy', 'Curriculum Developer', 'Instructor'],
    imageUrl: YamanAcharya,
    achievements: [
      'IAO Qualifier',
      'NAO Finalist',
      'Intern at Nepal Astronomical Society (NASO)'
    ],
    education: `Vishwa Adarsha College, Nepal '22`,
    linkedInUrl: 'https://www.linkedin.com/in/yaman-acharya-994250219/',
    gitHubUrl: ''
  },
  {
    name: 'Anurag Chittawar',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: AnuragChittawar,
    achievements: ['2x INBO gold medalist', 'AIR 52 in KVPY SA'],
    education: `Biology @ Indian Institute of Science '25`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Anuj Sakarda',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: AnujSakarda,
    achievements: [
      'USAMO Qualifier',
      'MIT PRIMES Researcher',
      'HCSSIM Alumnus',
      'PROMYS Mathematics Program'
    ],
    education: `Yale '26`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Susi Feng',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: SusiFeng,
    achievements: [],
    education: `Enloe High School, USA '21`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Albert Zhu',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: AlbertZhu,
    achievements: [],
    education: `Bellaire High School, USA '22`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Poonam Sahoo',
    roles: ['Math 2', 'Curriculum Developer', 'Instructor'],
    imageUrl: PoonamSahoo,
    achievements: [],
    education: `Math+CS @ Stanford '25`,
    linkedInUrl: 'https://www.linkedin.com/in/poonam-sahoo/',
    gitHubUrl: ''
  },
  {
    name: 'Olivia Li',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: OliviaLi,
    achievements: [
      'AIME Qualifier',
      'Math Prize for Girls Alumnus',
      'USACO Silver Qualifier'
    ],
    education: `Dougherty Valley High School, USA '23`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Sanjit Basker',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: SanjitBasker,
    achievements: [],
    education: `CS @ Cornell '23`,
    linkedInUrl: 'https://www.linkedin.com/in/sanjit-basker/',
    gitHubUrl: ''
  },
  {
    name: 'Jinha Kim',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: JinhaKim,
    achievements: [],
    education: `CS @ MIT '25`,
    linkedInUrl: 'https://www.linkedin.com/in/jinha-kim-b587631a7/',
    gitHubUrl: ''
  },
  {
    name: 'Jessica He',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: JessicaHe,
    achievements: [],
    education: `MIT '26`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Derek Chen',
    roles: ['Biology', 'Curriculum Developer', 'Instructor'],
    imageUrl: DerekChen,
    achievements: [
      '2021 USABO Team Member',
      '5x AIME Qualifier',
      'USAPhO Honorable Mention',
      'PROMYS Alumnus'
    ],
    education: `Belmont High School, USA '22`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Vismay Sharan',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: VismaySharan,
    achievements: [],
    education: `Yale '25`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Peter Luo',
    roles: ['Math 1', 'Curriculum Developer', 'Instructor'],
    imageUrl: PeterLuo,
    achievements: [],
    education: `Harvard '25`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Lauren Tan',
    roles: ['Astronomy', 'Curriculum Developer', 'Instructor'],
    imageUrl: LaurenTan,
    achievements: [],
    education: `SMK Taman Putri Kulai High School, Malaysia '21`,
    linkedInUrl: '',
    gitHubUrl: ''
  },
  {
    name: 'Jiyoung Lee',
    roles: ['Finance'],
    imageUrl: JiyoungLee,
    achievements: [],
    education: `Doctor of Pharmacy, Northeastern University '22`,
    linkedInUrl: 'https://www.linkedin.com/in/jiyoung-gina-lee-994b74159/',
    gitHubUrl: ''
  }
];

export { current, archive };
