import Biolympiads from '../../images/sponsors/Biolympiads.png';

const partners = [
  {
    name: 'Biolympiads',
    image: Biolympiads
  }
];

export default partners;
